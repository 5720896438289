<template>
  <Auth />
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Daftar Alamat</h5>
            <p class="mb-0 text-sm">
              Daftar alamat digunakan sbg endpoint pengirim dan penerima paket.
            </p>
          </div>
          <div class="card-body" style="border-top: 1px solid #eeeeee">
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6 text-end">
                <div class="btn-group" role="group">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click="showAddForm"
                  >
                    Tambah Data
                  </button>
                  <div class="dropdown" style="margin-left: 0px">
                    <button
                      id="dropdownMenuButton1"
                      style="border-radius: 0px 5px 5px 0px"
                      class="btn btn-sm btn-dark dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" @click="refreshTable"
                          >Refresh Tabel</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  v-model="pagination.perPage"
                  class="select-primary pagination-select"
                  placeholder="Per page"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <div>
                  <el-input
                    v-model="searchQuery"
                    type="search"
                    class="mb-3"
                    clearable
                    prefix-icon="el-icon-search"
                    style="width: 200px"
                    placeholder="Search records"
                    aria-controls="datatables"
                  >
                  </el-input>
                </div>
              </div>
              <div class="table-responsive">
                <el-table
                  :data="queriedData"
                  row-key="id"
                  header-row-class-name="thead-light"
                  @selection-change="selectionChange"
                >
                  <template #empty>
                    <div class="flex items-center justify-center h-100%">
                      <el-empty />
                    </div>
                  </template>
                  <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                  >
                  </el-table-column>
                  <el-table-column
                    align="left"
                    label="Alamat"
                  >
                    <template #default="props">
                      {{ props.row.address }}<br /><span
                        style="color: #bbbbbb"
                        >{{ props.row.area }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="left"
                    label="Status"
                  >
                    <template #default="props">
                      <span v-if="props.row.pickup == '1'">
                        <argon-badge
                          size="sm"
                          variant="gradient"
                          color="success"
                        >
                          Pickup
                        </argon-badge>
                      </span>
                      <span v-else>-</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    min-width="100px"
                    align="left"
                    label="Actions"
                  >
                    <template #default="props">
                      <a v-show="false">
                        <i class="fas fa-eye text-secondary"></i>
                      </a>
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Edit data"
                        placement="top-start"
                      >
                        <a
                          class="mx-3"
                          href="#"
                          @click.prevent="showEditForm(props.$index, props.row)"
                        >
                          <i class="fas fa-user-edit text-primary"></i>
                        </a>
                      </el-tooltip>
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Hapus data"
                        placement="top-start"
                      >
                        <a
                          href="#"
                          @click.prevent="handleDelete(props.$index, props.row)"
                        >
                          <i class="fas fa-trash text-danger"></i>
                        </a>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <br />
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <base-pagination
                  v-model="pagination.currentPage"
                  class="pagination-no-border"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal v-model:show="modals.classic" size="lg">
    <div class="p-2">
      <form @submit.prevent="onSubmit">
        <input v-model="form.id" type="hidden" />
        <input v-model="form.district_id" type="hidden" />
        <input v-model="form.ro_subdistrict_id" type="hidden" />
        <h6>Data Kontak</h6>
        <div class="row">
          <div class="col-md-4">
            <label class="form-label">Nama (dapat dihubungi)</label>
            <input
              v-model="form.name"
              type="text"
              class="form-control form-control-lg"
              maxlength="50"
              required
            />
            <small
              ><span class="form-text"><i>* kolom wajib diisi</i></span></small
            >
          </div>
          <div class="col-md-4">
            <label class="form-label">No Handphone</label>
            <input
              v-model="form.phone"
              type="text"
              maxlength="50"
              class="form-control form-control-lg"
            />
            <small
              ><span class="form-text"><i>* kolom wajib diisi</i></span></small
            >
          </div>
          <div class="col-md-4">
            <label class="form-label">Email</label>
            <input
              v-model="form.email"
              type="email"
              maxlength="100"
              class="form-control form-control-lg"
            />
          </div>
        </div>
        <h6 class="mt-3">Data Alamat</h6>
        <div class="row">
          <div class="col-md-6">
            <div>
              <label class="form-label">Kecamatan</label>
              <AutocompleteZonecode
                v-if="showAutocompleteZonecode"
                id="origin"
                name="origin"
                name-i-d="ro_subdistrict_id"
                clasname="form-control form-control-lg"
                :urlsearch="urlsearch"
                :value-i-d="form.ro_subdistrict_id"
                :value-area="form.area"
                get_district_id="yes"
                @autocompletecallback="getAutocompleteCallback"
              />
            </div>
            <div class="mt-3">
              <label class="form-label">Kelurahan</label>
              <select
                v-model="form.subdistrict_id"
                class="form-select form-select-lg"
              >
                <option
                  v-for="select in selects.subdistricts"
                  :key="select.value"
                  :value="select.value"
                >
                  {{ select.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div>
              <label class="form-label">Alamat</label>
              <textarea
                v-model="form.address"
                row=""
                class="form-control form-control-lg"
                maxlength="255"
                style="height: 70px"
              />
            </div>
            <div style="max-width: 200px">
              <label class="form-label">Kodepos</label>
              <input
                v-model="form.postalcode"
                type="number"
                maxlength="7"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <label class="form-label">Tipe Alamat</label>
            <select
              v-model="form.address_type"
              class="form-select form-select-lg"
            >
              <option
                v-for="select in selects.address_types"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <br />
            <argon-checkbox
              id="checkboxPickup"
              name="pickup"
              :checked="checkedPickup"
              @click="handlePickup"
              >Jadikan ini alamat Pickup</argon-checkbox
            >
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6"></div>
          <div class="col-md-6"></div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6"></div>
          <div class="col-md-6"></div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6"></div>

          <div class="col-md-6"></div>
        </div>

        <hr style="margin-bottom: -20px" />
        <argon-button
          color="primary"
          variant="gradient"
          class="my-4 mb-2"
          :loading="isLoading"
          >Submit Data</argon-button
        >
        &nbsp;
        <button
          type="button"
          class="btn btn-link"
          style="margin-top: 35px"
          @click="modals.classic = false"
        >
          Close
        </button>
      </form>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Auth from "@/components/auth/Auth";
import Modal from "@/components/Modal";
import Swal from "sweetalert2";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import AutocompleteZonecode from "@/components/AutocompleteZonecode.vue";
import {
  ElTable,
  ElTableColumn,
  ElEmpty,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElTooltip,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

export default {
  name: "AddressLists",
  components: {
    BasePagination,
    Auth,
    ArgonButton,
    ArgonBadge,
    ArgonCheckbox,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    ElEmpty,
    ElTooltip,
    Modal,
    AutocompleteZonecode,
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      urlread: "/api/v1/address_lists/read",
      urlstore: "/api/v1/address_lists/store",
      urlupdate: "/api/v1/address_lists/update",
      urldelete: "/api/v1/address_lists/delete",
      urlsearch: "/api/v1/search_zonecodes",
      dataTable: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "code",
        "name",
        "phone",
        "email",
        "address",
        "postalcode",
        "area",
      ],
      tableColumns: [
        {
          type: "selection",
        },
        {
          prop: "code",
          label: "Kode",
          sortable: true,
        },
        {
          prop: "name",
          label: "Nama",
          sortable: true,
        },
      ],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      loadingTable: true,

      selects: {
        pickups: [
          {
            value: "1",
            name: "Iya",
          },
          {
            value: "0",
            name: "Tidak",
          },
        ],
        address_types: [
          {
            value: "home",
            name: "Rumah",
          },
          {
            value: "office",
            name: "Kantor",
          },
        ],
        subdistricts: [],
      },

      form: {
        id: "",
        district_id: "",
        name: "",
        phone: "",
        email: "",
        area: "",
        ro_subdistrict_id: "",
        subdistrict_id: "",
        address: "",
        postalcode: "",
        address_type: "",
        pickup: false,
      },
      showAutocompleteZonecode: true,
      checkedPickup: false,
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      return this.searchAction.slice(this.from, this.to);
    },
    searchAction() {
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          if (row[key] !== null) {
            let rowValue = row[key].toString().toLowerCase();
            if (
              rowValue.includes &&
              rowValue.includes(this.searchQuery.toLowerCase())
            ) {
              isIncluded = true;
            }
          }
        }
        return isIncluded;
      });

      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchAction.length > 0
        ? this.searchAction.length
        : this.tableData.length;
    },
  },
  created() {
    this.getTables();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async getSubDistrictSelect(district_id) {
      this.loadingService();
      try {
        const response = await axios.get(
          "api/v1/subdistrict/select/" + district_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.selects.subdistricts = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getTables() {
      try {
        this.loadingService();
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        //console.log(response);
        this.datas = response.data.datatables;
        this.tableData = this.datas;
        //console.log(this.datas);
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    refreshTable() {
      this.getTables();
    },
    clearForm() {
      for (let x in this.form) {
        this.form[x] = "";
      }
      this.checkedPickup = false;
      this.subdistricts = [];
      this.showAutocompleteZonecode = false;
      setTimeout(() => {
        this.showAutocompleteZonecode = true;
      }, 50);
    },
    showAddForm() {
      this.modals.classic = true;
      this.clearForm();
    },
    async showEditForm(index, row) {
      this.modals.classic = true;
      this.clearForm();
      this.loadingService();
      try {
        const response = await axios({
          method: "get",
          url: this.urlread + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let data = response.data;
        for (let x in this.form) {
          this.form[x] = data[x];
        }
        this.getSubDistrictSelect(this.form.district_id);
        this.showAutocompleteZonecode = false;
        setTimeout(() => {
          this.showAutocompleteZonecode = true;
        }, 500);

        this.checkedPickup = this.form.pickup;

        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        let sendmethod = "post";
        let sendurl = this.urlstore;
        if (this.form.id != "") {
          // update
          sendmethod = "put";
          sendurl = this.urlupdate;
        }
        const response = await axios({
          method: sendmethod,
          url: sendurl,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          //Swal.fire("Good job!", "Data berhasil disimpan!", "success");
          Swal.fire({
            title: "Good job!",
            html: "Data berhasil disimpan!",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
          // load table
          this.getTables();
          // close modal
          this.modals.classic = false;
          // clear form
          this.clearForm();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.errorRedirect500(error);
      }
    },
    handleDelete(index, row) {
      Swal.fire({
        title: "Konfirmasi!",
        html: `Yakin ingin menghapus data ini!<br /><b>` + row.name + `</b>`,
        showCancelButton: true,
        confirmButtonText: "Yes, hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteRow(row);
        }
      });
    },
    async deleteRow(row) {
      this.loadingService();
      try {
        const response = await axios({
          method: "delete",
          url: this.urldelete + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        //console.log(response.data);
        this.loadingService().close();
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          let indexToDelete = this.tableData.findIndex(
            (tableRow) => tableRow.id === row.id
          );
          if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
          }
          //Swal.fire("Good job!", "Data berhasil dihapus!", "success");
          Swal.fire({
            title: "Good job!",
            html: "Data berhasil dihapus!",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    handleSelectCity() {
      this.getCitySelect(this.form.province_id);
      this.form.ninja_zonecode_id = "";
      this.form.ro_subdistrict_id = "";
    },
    handleSelectDistrict() {
      this.getDistrictSelect(this.form.city_id);
      this.form.ninja_zonecode_id = "";
      this.form.ro_subdistrict_id = "";
    },
    handleSelectSubDistrict() {
      this.getSubDistrictSelect(this.form.district_id);
    },
    getAutocompleteCallback(value) {
      if (value.district_id != undefined) {
        this.form.district_id = value.district_id;
        this.form.ro_subdistrict_id = value.id;
        this.form.area = value.area;
        this.handleSelectSubDistrict();
      }
    },
    handlePickup(values) {
      this.form.pickup = values.target.checked;
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
