<template>
  <div>
  </div>
</template>

<script>
export default {
    name: "Auth",
    data() {
        return {
            token: '',
            refresh_token:'',
            auth: '',
            payload: '',
        }
    },
    created() {
        const token = localStorage.getItem("token");
        const refresh_token = localStorage.getItem("refresh_token");
        const auth = localStorage.getItem("auth");
        
        this.token = token;
        if((token == '') || (refresh_token == '') || (auth == '')) {
            this.$router.push({'path':'/login'});
        }
    },
}
</script>

<style>

</style>